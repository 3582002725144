import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
    <NewsArticle>
      <h1 className='page-title'>Changes to our mortgage products</h1>
      <p>With effect from Tuesday 1 March 2022 we will be making the following changes to our mortgage product range:</p>
      <p><strong>Summary of changes</strong></p>
      <p>With effect from Tuesday 1 March 2022 we are changing our Residential Standard Variable Rate from 3.54% to 3.79% and our Buy to Let Standard Variable Rate from 4.60% to 4.85%.</p>
      <p>Any customers who are currently on our Residential or BTL Standard Variable Rates will be notified of changes to their payments in writing.</p>
      <p>We will also be making some further changes to our mortgage product ranges which include</p>
      
      <p><strong>Residential Existing Customer Switching Range</strong></p>
      <ul>
        <li>3 Year Fixed Standard at 90% LTV has reduced</li>
        <li>3 Year Fixed Fee Saver at 90% LTV has reduced</li>
        <li>5 Year Fixed Standard at 90% LTV has reduced</li>
        <li>5 Year Fixed Fee Saver at 90% LTV has reduced</li>
        <li>5 Year Premier Exclusive at 90% LTV has reduced</li>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>3 Year Fee Saver at 60%, 70%, 75% and 80% LTV have increased </li>
        <li>5 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% and 80% LTV have increased</li>
      </ul>
      
      <p><strong>Residential Existing Customer Borrowing More Range</strong></p>
      <ul>
        <li>3 Year Fixed Standard at 90% LTV has reduced</li>
        <li>3 Year Fixed Fee Saver at 90% LTV has reduced</li>
        <li>5 Year Fixed Standard at 90% LTV has reduced</li>
        <li>5 Year Fixed Fee Saver at 90% LTV has reduced</li>
        <li>5 Year Fixed Fee Saver at 90% LTV has reduced</li>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>3 Year Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% and 80% LTV have increased</li>
      </ul>
     
      <p><strong>Residential Remortgage Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fee Saver at 60%, 70% and 75% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      </ul>
      
      <p><strong>Residential First Time Buyer Range</strong></p>
      <ul>
        <li>3 Year Fixed Standard at 90% LTV has reduced</li>
        <li>3 Year Fixed Fee Saver at 90% LTV has reduced</li>
        <li>5 Year Fixed Standard at 90% LTV has reduced</li>
        <li>5 Year Fixed Fee Saver at 90% LTV has reduced</li>
        <li>5 Year Premier Exclusive at 90% LTV has reduced</li>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>3 Year Fee Saver at 60%, 70% and 75% have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% and 80% LTV have increased</li>
      </ul>
      
      <p><strong>Residential Home Mover Range</strong></p>
      <ul>
        <li>3 Year Fixed Standard at 90% LTV has reduced</li>
        <li>3 Year Fixed Fee Saver at 90% LTV has reduced</li>
        <li>5 Year Fixed Standard at 90% LTV has reduced</li>
        <li>5 Year Fixed Fee Saver at 90% LTV has reduced</li>
        <li>5 Year Premier Exclusive at 90% LTV has reduced</li>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>3 Year Fee Saver at 60%, 70% and 75% have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% and 80% LTV have increased</li>
      </ul>
     
      <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      </ul>
      
      <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      </ul>
      
      <p><strong>UK Buy to Let Remortgage Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      </ul>
      <p><strong>UK Buy to Let Purchase Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      </ul>

      <p>There are no other changes to any of our other product ranges and all of our fixed rate end dates remain unchanged.</p>

      <p>An updated mortgage rate sheet will be published on Tuesday 1 March 2022 to reflect these changes.</p>

      <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
  </NewsArticle>
)

export default Article
